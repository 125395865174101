import React from 'react';
// Components
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Container, Button } from '@material-ui/core';
import './auth.scss';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';

// import { checkCompanyNameExist } from "../../components/API/API";
import API from '../../components/API/API';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#313131',
    },
  },
});

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.isDisabled = this.isDisabled.bind(this);
    this.passwordFocus = this.passwordFocus.bind(this);
    this.switchMode = this.switchMode.bind(this);
    this.toggleSucces = this.toggleSucces.bind(this);
    const { pathname } = this.props.location;
    //this could be rewritten using route variables. cba
    var currentMode = 'login';
    if (pathname === '/auth/register') {
      currentMode = 'register';
    }
    this.state = {
      mode: currentMode,
      open: false,
      email: '',
      password: '',
      success: false,
      email_error_text: null,
      password_error_text: null,
      auth_error_text: null,
      disabled: true,
    };
  }
  progressStyle = {
    display: 'none',
  };
  validatorListTextStyles = {
    characters: {
      text: 'Your password must be between 8 and 32 characters',
      style: {
        display: 'block',
      },
    },
    capital: {
      text: 'Your password must have at least one capital letter',
      style: {
        display: 'block',
      },
    },
    lowercase: {
      text: 'Your password must have at least one lowercase letter',
      style: {
        display: 'block',
      },
    },
    num: {
      text: 'Your password must have at least one number',
      style: {
        display: 'block',
      },
    },
    special: {
      text: 'Your password must have at least one of these special characters: #?!@$%^&*-',
      style: {
        display: 'block',
      },
    },
  };
  componentDidMount() {
    API.isLoggedIn()
      .then((e) => {
        if (e.data.auth === true) {
          this.props.history.push('/dashboard');
        }
      })
      .catch((e) => {
      });
  }
  toggleSucces() {
    //temp at top to test API
    switch (this.state.mode) {
      case 'login':
        API.login(this.state.email, this.state.password)
          .then((e) => {
            localStorage.setItem('token', e.data.login);
            this.setState({ ...this.state, success: true });
            setTimeout(() => {
              this.props.history.push('/dashboard');
            }, 1000);
          })
          .catch((e) => {
            if (e.graphQLErrors && e.graphQLErrors[0]) {
              if (e.graphQLErrors[0].message === 'Authentication failed') {
                this.setState({
                  ...this.state,
                  auth_error_text:
                    'The email or password you entered isn’t correct',
                });
              }
            }
          });
        break;
      case 'register':
        API.register(this.state.email, this.state.password)
          .then((e) => {
            localStorage.setItem('token', e.data.register);
            this.setState({ ...this.state, success: true });
            setTimeout(() => {
              this.props.history.push('/auth/registration');
            }, 1000);
          })
          .catch((e) => {
            if (e.graphQLErrors && e.graphQLErrors[0]) {
              if (e.graphQLErrors[0].message === 'User not in Beta program') {
                this.setState({
                  ...this.state,
                  auth_error_text:
                    'The email you tried to register with is not in the beta program',
                });
              }
            }
          });
        break;
      default:
        return;
    }
  }
  switchMode() {
    this.setState({
      ...this.state,
      auth_error_text: null,
      mode: this.state.mode === 'login' ? 'register' : 'login',
    });
  }
  passwordValidationSucces = false;
  emailFocus() {
    this.setState({
      ...this.state,
      auth_error_text: null,
      email_error_text: null,
    });
  }
  passwordFocus(showPwdScreen) {
    if (this.state.mode !== 'login') {
      this.progressStyle = {
        display: showPwdScreen ? 'block' : 'none',
      };
    }

    this.setState({
      ...this.state,
      auth_error_text: null,
      password_error_text: null,
    });
  }
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  isDisabled(arg) {
    const currentEmail = arg.email || this.state.email;
    const currentPassword = arg.password || this.state.password;
    let emailIsValid = false; //potentially useless
    let passwordIsValid = false; //potentially useless
    emailIsValid = this.isEmpty(currentEmail)
      ? false
      : this.validateEmail(currentEmail);
    passwordIsValid = this.isEmpty(currentPassword)
      ? false
      : this.passwordValidationSucces || this.state.mode === 'login'
      ? !(currentPassword.length < 8 || currentPassword.length > 32)
        ? true
        : false
      : false;
    if (emailIsValid && passwordIsValid) {
      return { disabled: false };
    } else {
      return { disabled: true };
    }
  }
  isEmpty(e) {
    return e === '' || !e;
  }
  toggleBlurText(e, type) {
    let errorText = null;
    const value = e.target.value;
    switch (type) {
      case 'email':
        errorText = this.isEmpty(value)
          ? "E-Mail can't be empty"
          : this.validateEmail(value)
          ? null
          : 'Sorry, this is not a valid email';
        this.setState({ ...this.state, email_error_text: errorText });
        break;
      case 'password':
        errorText = this.isEmpty(value)
          ? "Password can't be empty"
          : this.passwordValidationSucces || this.state.mode === 'login'
          ? !(value.length < 8 || value.length > 32)
            ? null
            : 'Invalid password'
          : 'Your password is missing some requirements';
        this.setState({ ...this.state, password_error_text: errorText });
        break;
      default:
        return;
    }
  }

  validatePassword(e) {
    function testString(value, tester) {
      return tester.test(String(value));
    }
    let pwdTester = 0;
    //test capital
    this.validatorListTextStyles.capital.style = testString(
      e.target.value,
      /^(?=.*?[A-Z])\w+/
    )
      ? { display: 'none' }
      : { display: 'block' };
    if (testString(e.target.value, /^(?=.*?[A-Z])\w+/)) pwdTester++;
    //lower case
    this.validatorListTextStyles.lowercase.style = testString(
      e.target.value,
      /^(?=.*?[a-z])\w+/
    )
      ? { display: 'none' }
      : { display: 'block' };
    if (testString(e.target.value, /^(?=.*?[a-z])\w+/)) pwdTester++;
    //num
    this.validatorListTextStyles.num.style = testString(
      e.target.value,
      /^(?=.*?[0-9])\w+/
    )
      ? { display: 'none' }
      : { display: 'block' };
    if (testString(e.target.value, /^(?=.*?[0-9])\w+/)) pwdTester++;
    //special character
    this.validatorListTextStyles.special.style = testString(
      e.target.value,
      /^(?=.*?[#?!@$%^&*-])\w+/
    )
      ? { display: 'none' }
      : { display: 'block' };
    if (testString(e.target.value, /^(?=.*?[#?!@$%^&*-])\w+/)) pwdTester++;
    //pwd length
    this.validatorListTextStyles.characters.style =
      e.target.value.length >= 8 && e.target.value.length <= 32
        ? { display: 'none' }
        : { display: 'block' };
    if (e.target.value.length >= 8 && e.target.value.length <= 32) pwdTester++;
    if (pwdTester === 5) {
      this.passwordValidationSucces = true;
    } else {
      this.passwordValidationSucces = false;
    }
    this.changeValue(e, 'password');
  }
  changeValue(e, type) {
    const value = e.target.value;
    const nextState = {};
    nextState[type] = value;
    this.setState({
      ...this.state,
      ...nextState,
      ...this.isDisabled(nextState),
      auth_error_text: null,
    });
  }
  toggleSuccesShowMark() {
    this.setState({ ...this.state, success: true });

    setTimeout(() => {
      this.setState({ ...this.state, success: false });
      // this.props.history.push("/auth/registration");
    }, 1000);
  }
  // toggleSucces() {
  //   this.setState({ ...this.state, success: true });

  //   setTimeout(() => {
  //     // this.props.history.push("/auth/registration");
  //   }, 1000);
  // }
  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   if (this.props !== prevProps) {
  //     this.setState(this.props);
  //   }
  // if(this.state.auth_error_text !== null){
  // }
  // }
  render() {
    return (
      <Container id="loginContainer">
        <div className="section">
          <div id="banner">
            <Link className="homeLink" to="/">
              <h1 id="authTitle">OGA Fitness</h1>
            </Link>
          </div>
          <div
            id="loginScreen"
            className={!this.state.success ? 'success-shown' : 'success-hidden'}
          >
            <h2 className="title">
              {this.state.mode === 'login' ? 'Login' : 'Register a new account'}
            </h2>
            <form id="loginForm">
              <h4 className="errorText">{this.state.auth_error_text}</h4>
              <TextField
                id="outlined-basic"
                className="loginInput"
                label="E-Mail"
                variant="outlined"
                error={this.state.email_error_text}
                helperText={this.state.email_error_text}
                onFocus={() => this.emailFocus()}
                onChange={(e) => this.changeValue(e, 'email')}
                onBlur={(e) => this.toggleBlurText(e, 'email')}
              />
              <TextField
                id="outlined-basic"
                className="loginInput"
                label="Password"
                variant="outlined"
                type="password"
                error={this.state.password_error_text}
                helperText={this.state.password_error_text}
                onFocus={() => this.passwordFocus(true)}
                onChange={(e) => this.validatePassword(e)}
                onBlur={(e) => {
                  this.passwordFocus(false);
                  this.toggleBlurText(e, 'password');
                }}
              />
              <div className="listtest" style={this.progressStyle}>
                {Object.keys(this.validatorListTextStyles).map((i) => {
                  return (
                    <p
                      key={i}
                      className="validatorListText"
                      style={this.validatorListTextStyles[i].style}
                    >
                      {this.validatorListTextStyles[i].text}
                    </p>
                  );
                })}
              </div>
              <p className="termPrivAccept">
                By creating a new ogafit account, you agree to the Terms of
                Service and Privacy Policy
              </p>
              <Button
                // type="submit"
                variant="contained"
                // color="inherit"
                style={
                  this.state.disabled
                    ? { backgroundColor: 'gray' }
                    : { color: 'white' }
                }
                className="loginSubmit"
                disabled={this.state.disabled}
                onClick={() => this.toggleSucces()}
              >
                {this.state.mode === 'login' ? 'Login' : 'Register'}
              </Button>
              <Button className="regButton" onClick={() => this.switchMode()}>
                {this.state.mode === 'login'
                  ? 'Register instead'
                  : 'Already have an account? Log in'}
              </Button>
            </form>
          </div>
          <div
            className={!this.state.success ? 'success-shown' : 'success-hidden'}
          >
            <div className="altLogin">
              <span className="altLoginText">
                Or {this.state.mode === 'login' ? 'login' : 'register'} with:
              </span>
              <div>
                <ThemeProvider theme={theme}>
                  <Button
                    className="altLoginButton"
                    color="primary"
                    variant="contained"
                    disabled
                  >
                    <img
                      src="/assets/images/discOrigin.svg"
                      className="altLogo"
                      alt="logo"
                    />
                    <p>
                      {' '}
                      {this.state.mode === 'login' ? 'log in' : 'register'} with
                      discord (in development)
                    </p>
                  </Button>
                  <Button
                    className="altLoginButton"
                    color="primary"
                    variant="contained"
                    onClick={() => this.toggleSuccesShowMark()}
                    disabled
                  >
                    <img
                      src="/assets/images/glogo.svg"
                      className="altLogo"
                      alt="logo"
                    />
                    <p>
                      {' '}
                      {this.state.mode === 'login' ? 'log in' : 'register'} with
                      Google (in development)
                    </p>
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.success ? 'success-shown' : 'success-hidden'}
        >
          {/* <Button>Thank you</Button> */}
          {/* <div className="checkmark">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 161.2 161.2" enable-background="new 0 0 161.2 161.2" space="preserve">
                <path className="path" fill="none" stroke="#7DB0D5" stroke-miterlimit="10" d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                  c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                  c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"/>
                <circle className="path" fill="none" stroke="#7DB0D5" stroke-width="4" stroke-miterlimit="10" cx="80.6" cy="80.6" r="62.1"/>
                <polyline className="path" fill="none" stroke="#7DB0D5" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="113,52.8 
                  74.1,108.4 48.2,86.4 "/>
            </svg>
            <h3>Registration Successful!</h3> */}
          <div className={this.state.success ? 'checkmark' : 'checkmarkHidden'}>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 161.2 161.2"
              enableBackground="new 0 0 161.2 161.2"
              space="preserve"
            >
              <path
                className={this.state.success ? 'path' : ''}
                fill="none"
                stroke="#7DB0D5"
                strokeMiterlimit="10"
                d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                  c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                  c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
              />
              <circle
                className={this.state.success ? 'path' : ''}
                fill="none"
                stroke="#7DB0D5"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="80.6"
                cy="80.6"
                r="62.1"
              />
              <polyline
                className={this.state.success ? 'path' : ''}
                fill="none"
                stroke="#7DB0D5"
                strokeWidth="6"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="113,52.8 
                  74.1,108.4 48.2,86.4 "
              />
            </svg>
            <h4>Registration Successful!</h4>
          </div>
        </div>
      </Container>
    );
  }
}
