import React from 'react';
// Components
import { Container, Snackbar, CircularProgress } from '@material-ui/core';
import './verifier.scss';
import MuiAlert from '@material-ui/lab/Alert';
import API from '../../../components/API/API';

export default class UserVerifier extends React.Component {
  token = this.props.match.params.token;
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    //this could be rewritten using route variables. cba
    this.state = {
      completed: false,
      resultTitle: '',
      resultText: '',
      succesMessage: false,
    };
  }

  handleClick() {
    this.setState({
      ...this.state,
      succesMessage: true,
    });
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      ...this.state,
      succesMessage: false,
    });
  }

  componentDidMount() {
    API.verifyRegistration(this.token)
      .then((e) => {
        if (e.data.verifyRegistration === true) {
          this.setState({
            ...this.state,
            completed: true,
            resultTitle: 'Verification complete!',
            resultText: 'Redirecting...',
            succesMessage: true,
          });
          this.props.history.push('/auth');
        }
      })
      .catch((e) => {
        this.setState({
          ...this.state,
          completed: true,
          resultTitle: 'This link is invalid',
          resultText:
            'This verification link is invalid. Please contact support',
        });
      });
  }

  render() {
    return (
      <Container id="verificationContainer">
        <div className="section">
          <div id="banner">
            {this.state.completed ? (
              <h1 id="verificationTitle">{this.state.resultTitle}</h1>
            ) : (
              <CircularProgress />
            )}
            <p>{this.state.resultText}</p>
          </div>
        </div>
        <Snackbar
          open={this.state.succesMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleClose}
            severity="success"
          >
            This is a success message!
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}
