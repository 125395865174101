import React, { Component } from 'react';
import './accountSettings.scss';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from '@material-ui/core';
import countries from '../../../utils/countries.json';
// import { Link } from "react-router-dom";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';
import API from '../../../components/API/API';

// //icons
// import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
// import CreditCardIcon from "@material-ui/icons/CreditCard";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.countriesList = [];
    countries.forEach((j) => {
      j.countries.forEach((e) => {
        if (e.name) this.countriesList.push(e);
      });
    });

    this.state = {
      unit: 'metric',
      email: '',
      disabledButton: false,
    };
    this.selectedDate = new Date();

    this.unitRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }
  validateInfo(currentValue) {
    //if stepcount is not defined in currentvalue, get it from state
    if (currentValue.stepCount === undefined) {
      currentValue.stepCount = this.state.activeStep;
    }
    // if no current value, return true (button disabled)
    if (!currentValue) {
      return true;
    }

    //Check if any variable is manually send (due to state updates)
    if (currentValue.first === undefined) {
      currentValue.first = this.state.first;
    }
    //test
    // currentValue.first =
    //   currentValue.first !== undefined ? "og" : "state";
    //always gets "og" ?
    //
    if (currentValue.last === undefined) {
      currentValue.last = this.state.last;
    }
    if (currentValue.birthday === undefined) {
      currentValue.birthday = this.state.birthday;
    }
    if (currentValue.gender === undefined) {
      currentValue.gender = this.state.gender;
    }
    if (currentValue.country === undefined) {
      currentValue.country = this.state.country;
    }
    if (currentValue.unit === undefined) {
      currentValue.unit = this.state.unit;
    }
    //check if any is empty
    if (
      currentValue.firstame !== '' &&
      currentValue.last !== '' &&
      currentValue.birthday !== '' &&
      currentValue.gender !== '' &&
      currentValue.country !== '' &&
      currentValue.unit !== ''
    ) {
      //button enabled
      return false;
    } else {
      //button disabled. empty input
      return true;
    }
  }

  handleChange(e) {
    let change = {};

    change[e.target.name] = e.target.value;
    if (e.target.value) {
      API.updateSettings(change).catch((e) => {
      });
    }
    this.setState({
      ...this.state,
      ...change,
      // disabledButton: this.validateInfo(change),
    });
  }

  componentDidMount() {
    API.isLoggedIn()
      .then((e) => {
        if (e.data.auth !== true) {
          this.props.history.push('/auth');
        }
        API.getUserSettings(['unit'])
          .then((e) => {
            this.setState({
              ...this.state,
              ...e.data.getUserSettings,
              // disabledButton: this.validateInfo(e.data.getUserSettings),
            });
          })
          .catch((e) => {
            this.props.history.push('/auth');
          });
      })
      .catch((e) => {
        this.props.history.push('/auth');
      });
  }
  render() {
    return (
      // <>
      <div id="userSettings">
        <div id="contentCard" style={{ border: 'none', boxShadow: 'none' }}>
          <div id="content" style={{ border: 'none', boxShadow: 'none' }}>
            <div id="topIcon">
              <AccountCircleIcon fontSize="large" />
              <h2>Your info</h2>
            </div>
            <TextField
              name="email"
              // ref={this.firstRef}
              className="userInput"
              variant="outlined"
              // onChange={this.handleChange}
              label="E-mail"
              type="text"
              // value={this.state.first}
              // required
              disabled
            />
            <FormControl
              key="unit"
              variant="outlined"
              className="userInput"
              required
            >
              <InputLabel id="unit-label">Unit</InputLabel>
              <Select
                name="unit"
                type="select"
                ref={this.unitRef}
                labelId="unit-label"
                variant="outlined"
                label="Unit"
                onChange={this.handleChange}
                value={this.state.unit}
                required
              >
                <MenuItem key="metric" value="metric">
                  Metric
                </MenuItem>
                <MenuItem key="imperial" value="imperial">
                  Imperial
                </MenuItem>
              </Select>
            </FormControl>

            <p id="subtitleText">Any changes are automatically saved</p>
            <Link
              to={this.state.disabledButton ? '#' : '/dashboard'}
              className="linkFix"
              disabled={this.state.disabledButton}
            >
              <Button
                className="dashboardCloseItemButton"
                variant="contained"
                disabled={this.state.disabledButton}
                // color="primary"
              >
                Back to dashboard
              </Button>
            </Link>
            <p
              style={
                this.state.disabledButton
                  ? { fontSize: '0.8em', color: 'red' }
                  : { display: 'none' }
              }
            >
              Please fill in all fields
            </p>
          </div>
        </div>
      </div>
    );
  }
}
