import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect  } from "react-router-dom";
// Pages
import Home from "./pages/Home/Home";
// Auth
import Login from "./pages/Auth/Login";
import RegisterStepper from "./pages/Auth/RegisterStepper";
import UserVerifier from "./pages/Auth/verifier/Verifier";
// User
import Dashboard from "./pages/User/Dashboard/Dashboard";
import UserSettings from "./pages/User/UserSettings/UserSettings";
import accountSettings from "./pages/User/accountSettings/accountSettings";
//Admin
import BetaUsers from "./pages/User/Admin/BetaInterface/BetaInterface";


// import PaymentSettings from "./pages/User/paymentSettings/paymentSettings";

// import Test from "./pages/Test/Test";
// const SwitchWithSlide = animateSwitch(Switch, SlideOut);
export default class Routes extends React.Component {
  
  render() {

    return (
      <div>
        <Router>
            <Switch>
              <Route exact path="/auth" component={Login} />
              <Route exact path="/auth/login" component={Login} />
              <Route exact path="/auth/register" component={Login} />
              <Route exact path="/auth/registration" component={RegisterStepper} />
              <Route exact path="/auth/verification/:token" component={UserVerifier} />
              <Route exact path="/dashboard" component={Dashboard} />
              
              {/* <Route exact path="/account/:item" component={Dashboard} /> */}
              {/* Original dashboard routing due to material bug in strictmode */}

              <Route exact path="/account/user" component={UserSettings} />
              <Route exact path="/account/settings" component={accountSettings} />
              <Route exact path="/admin/betausers" component={BetaUsers} />
              

              {/* <Route exact path="/userSettings" component={UserSettings} /> */}
              <Route exact path="/" component={Home} />
              <Redirect from='*' to='/' />
            </Switch>
        </Router>
      </div>
    );
  }
}
