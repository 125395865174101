import React, { Component } from 'react';
import './userSettings.scss';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from '../../../utils/countries.json';
// import { Link } from "react-router-dom";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';
import API from '../../../components/API/API';

// //icons
// import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
// import CreditCardIcon from "@material-ui/icons/CreditCard";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.countriesList = [];
    countries.forEach((j) => {
      j.countries.forEach((e) => {
        if (e.name) this.countriesList.push(e);
      });
    });

    this.state = {
      first: '',
      last: '',
      birthday: new Date().setFullYear(new Date().getFullYear() - 13),
      gender: '',
      country: '',
      disabledButton: true,
    };
    this.selectedDate = new Date();

    this.firstRef = React.createRef();
    this.lastRef = React.createRef();
    this.birthdayRef = React.createRef();
    this.genderRef = React.createRef();
    this.countryRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }
  validateInfo(currentValue) {
    //if stepcount is not defined in currentvalue, get it from state
    if (currentValue.stepCount === undefined) {
      currentValue.stepCount = this.state.activeStep;
    }
    // if no current value, return true (button disabled)
    if (!currentValue) {
      return true;
    }

    //Check if any variable is manually send (due to state updates)
    if (currentValue.first === undefined) {
      currentValue.first = this.state.first;
    }
    //test
    // currentValue.first =
    //   currentValue.first !== undefined ? "og" : "state";
    //always gets "og" ?
    //
    if (currentValue.last === undefined) {
      currentValue.last = this.state.last;
    }
    if (currentValue.birthday === undefined) {
      currentValue.birthday = this.state.birthday;
    }
    if (currentValue.gender === undefined) {
      currentValue.gender = this.state.gender;
    }
    if (currentValue.country === undefined) {
      currentValue.country = this.state.country;
    }
    if (currentValue.unit === undefined) {
      currentValue.unit = this.state.unit;
    }
    //check if any is empty
    if (
      currentValue.firstame !== '' &&
      currentValue.last !== '' &&
      currentValue.birthday !== '' &&
      currentValue.gender !== '' &&
      currentValue.country !== '' &&
      currentValue.unit !== ''
    ) {
      //button enabled
      return false;
    } else {
      //button disabled. empty input
      return true;
    }
  }

  handleChange(e) {
    let change = {};

    change[e.target.name] = e.target.value;
    if (e.target.value) {
      API.updateUser(change).catch((e) => {
      });
    }
    this.setState({
      ...this.state,
      ...change,
      disabledButton: this.validateInfo(change),
    });
  }
  handleDateChange = (date) => {
    // date = new Date().setUTCSeconds(date);
    if (date) {
      date = new Date(date);
      API.updateUser({ birthday: date }).catch((e) => {
        // this.props.history.push("/auth");
      });
    }

    this.setState({
      ...this.state,
      birthday: date,
      disabledButton: this.validateInfo({ birthday: date }),
    });
  };
  onTagsChange = (event, values) => {
    if (!values || !values.name) return;
    API.updateUser({ country: values.name }).catch((e) => {
      // this.props.history.push("/auth");
    });
    this.setState(
      {
        ...this.state,
        country: values.name,
        disabledButton: this.validateInfo({ country: values.name }),
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
      }
    );
  };
  componentDidMount() {
    API.isLoggedIn()
      .then((e) => {
        if (e.data.auth !== true) {
          this.props.history.push('/auth');
        }
        API.getUserInfo(['first', 'last', 'birthday', 'gender', 'country'])
          .then((e) => {
            this.setState({
              ...this.state,
              ...e.data.getUserInfo,
              disabledButton: this.validateInfo(e.data.getUserInfo),
            });
          })
          .catch((e) => {
            this.props.history.push('/auth');
          });
      })
      .catch((e) => {
        this.props.history.push('/auth');
      });
  }
  render() {
    return (
      <>
        <div id="userSettings">
          {/* <div id="banner">
            <Link className="homeLink" to="/">
              <h1 id="authTitle">OGA Fitness</h1>
            </Link>
          </div> */}
          <div id="contentCard" style={{ border: 'none', boxShadow: 'none' }}>
            <div id="content" style={{ border: 'none', boxShadow: 'none' }}>
              <div id="topIcon">
                <AccountCircleIcon fontSize="large" />
                <h2>Your info</h2>
              </div>
              <TextField
                name="first"
                ref={this.firstRef}
                className="userInput"
                variant="outlined"
                onChange={this.handleChange}
                label="First name"
                type="text"
                value={this.state.first}
                required
              />
              <TextField
                name="last"
                ref={this.lastRef}
                className="userInput"
                label="Last name"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.last}
                type="text"
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  className="userInput"
                  animateYearScrolling
                  // className="large-field"
                  disableFuture={true}
                  format=" DD / MM / yyyy "
                  InputAdornmentProps={{ position: 'start' }}
                  inputVariant="outlined"
                  label="Birthday"
                  minDate={new Date().setFullYear(
                    new Date().getFullYear() - 80
                  )}
                  maxDate={new Date().setFullYear(
                    new Date().getFullYear() - 13
                  )}
                  name="birthday"
                  openTo="year"
                  onChange={this.handleDateChange}
                  // ref={this.birthdayRef}
                  value={new Date(this.state.birthday)}
                />
              </MuiPickersUtilsProvider>
              <FormControl
                key="gender"
                variant="outlined"
                // className="field"
                className="userInput"
                required
              >
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  name="gender"
                  // type="select"
                  ref={this.genderRef}
                  labelId="gender-label"
                  variant="outlined"
                  // label="Gender"
                  onChange={this.handleChange}
                  value={this.state.gender || 'male'}
                  required
                >
                  <MenuItem key="male" value="male">
                    Male
                  </MenuItem>
                  <MenuItem key="female" value="female">
                    Female
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl
                key="country"
                variant="outlined"
                // className="large-field"
                className="userInput"
                required
              >
                <Autocomplete
                  id="combo-box-demo"
                  freeSolo
                  options={this.countriesList}
                  variant="outlined"
                  value={this.state.country}
                  onChange={this.onTagsChange}
                  getOptionLabel={(option) =>
                    option.name ? option.name : option
                  }
                  renderInput={(c) => (
                    <TextField
                      {...c}
                      name="country"
                      label="Country"
                      variant="outlined"
                      value={c.name}
                    />
                  )}
                />
              </FormControl>

              {/* <TextField
                name="email"
                className="userInput"
                variant="outlined"
                value={this.state.user.email}
                onChange={(e) => this.updateValue(e)}
                label="E-mail"
                type="text"
              /> */}
              {/* <TextField
                name="phone"
                className="userInput"
                variant="outlined"
                value={this.state.user.phone}
                onChange={(e) => this.updateValue(e)}
                label="Phone number"
                type="text"
              /> */}
              <p id="subtitleText">Any changes are automatically saved</p>
              {/* <Button
              onClick={() => this.props.returnFuntion.closeItem()}
              id=""
              >Back to dashboard</Button> */}
              <Link
                to={this.state.disabledButton ? '#' : '/dashboard'}
                className="linkFix"
                disabled={this.state.disabledButton}
              >
                <Button
                  className="dashboardCloseItemButton"
                  variant="contained"
                  disabled={this.state.disabledButton}
                  // color="primary"
                >
                  Back to dashboard
                </Button>
              </Link>
              <p
                style={
                  this.state.disabledButton
                    ? { fontSize: '0.8em', color: 'red' }
                    : { display: 'none' }
                }
              >
                Please fill in all fields
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
