import React from "react";
import "./App.scss";



import Routes from "./routes.jsx";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
// import client from "./api";

// Redux
import { connect } from "react-redux";
// import store from "./store";

// Components
// import { ApolloProvider } from "@apollo/client";
// import Dialog from "./components/Dialog/Dialog";
// import {doQuery} from './components/API/API'

// Theme
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3ddafe",
      disabled:"#ffffff",
    },
    disabled:{
      main:"grey",
    },
    text: {
      primary: "#fff",
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  // componentDidMount() {
  //   this.store = store.subscribe(() => {
  //     this.setState({
  //       ...this.state,
  //       ...store.getState(),
  //     });
  //   });

  //   this.setState({
  //     ...this.state,
  //     ...store.getState(),
  //   });
  // }

  render() {
    return (
      <div className="App">
          <ThemeProvider theme={theme}>
            {/* <CssBaseline/> */}
            {/* <Dialog
              open={this.state?.app?.dialog?.open}
              type={this.state?.app?.dialog?.type}
              title={this.state?.app?.dialog?.title}
              description={this.state?.app?.dialog?.description}
              content={this.state?.app?.dialog?.content}
              actions={this.state?.app?.dialog?.actions}
            /> */}
            <Routes /> {/* Page routes */}
          </ThemeProvider>
      </div>
    );
  }
}

export default connect()(App);
