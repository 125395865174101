import React, { Component } from 'react';
// Components
import {
  IconButton,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Fab,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from '../../utils/countries.json';
import API from '../../components/API/API';
import { Link } from 'react-router-dom';


// Icons
import { ArrowForward, ArrowBack, Check } from '@material-ui/icons';

// Styles
import './Stepper.scss';

export class RegisterStepper extends Component {
  constructor(props) {
    super();
    this.countriesList = [];
    countries.forEach((j) => {
      j.countries.forEach((e) => {
        if (e.name) this.countriesList.push(e);
      });
    });
    this.state = {
      activeStep: props.activeStep || 0,
      first: '',
      last: '',
      birthday: new Date().setFullYear(new Date().getFullYear() - 13),
      gender: '',
      country: '',
      unit: 'metric',
      weight: 0,
      length: 0,
      disabledButton: true,
    };
    this.selectedDate = new Date();
    // Refs
    this.firstRef = React.createRef();
    this.lastRef = React.createRef();
    this.birthdayRef = React.createRef();
    this.genderRef = React.createRef();
    this.countryRef = React.createRef();
    this.unitRef = React.createRef();
    this.weightRef = React.createRef();
    this.lengthRef = React.createRef();

    // Binds
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderSection = this.renderSection.bind(this);
  }
  handleDateChange = (date) => {
    // date = new Date().setUTCSeconds(date);
    date = new Date(date);
    API.updateUser({ birthday: date }).catch((e) => {
      // this.props.history.push("/auth");
    });
    this.setState({
      ...this.state,
      birthday: date,
    });
  };
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        ...this.props,
      });
    }
  }

  handleNext() {
    if (this.state.activeStep < 3) {
      //i know this is weird
      if (this.state.activeStep !== 2)
        this.setState({
          ...this.state,
          activeStep: this.state.activeStep + 1,
        });
    }
  }

  handleBack() {
    if (this.state.activeStep > 0) {
      this.setState({
        ...this.state,
        activeStep: this.state.activeStep - 1,
      });
    }
  }

  handleChange(e) {
    let change = {};

    change[e.target.name] = e.target.value;
    if (e.target.name === 'unit') {
      const updateValue = e.target.name === 'metric' ? true : false;
      API.updateSettings({ [e.target.name]: updateValue }).catch((e) => {
        // this.props.history.push("/auth");
      });
    } else {
      API.updateUser(change).catch((e) => {
        // this.props.history.push("/auth");
      });
    }
    this.setState({
      ...this.state,
      ...change,
      disabledButton: this.validateInfo(change),
    });
  }
  onTagsChange = (event, values) => {
    if (!values || !values.name) return;
    API.updateUser({ country: values.name }).catch((e) => {
      // this.props.history.push("/auth");
    });
    this.setState(
      {
        ...this.state,
        country: values.name,
        disabledButton: this.validateInfo({ country: values.name }),
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
      }
    );
  };
  componentDidMount() {
    API.isLoggedIn()
      .then((e) => {
        if (e.data.auth !== true) {
          this.props.history.push('/auth');
        }
        API.getUserInfo(['first', 'last', 'birthday', 'gender', 'country'])
          .then((e) => {
            this.setState({
              ...this.state,
              ...e.data.getUserInfo,
              disabledButton: this.validateInfo(e.data.getUserInfo),
            });
          })
          .catch((e) => {
            this.props.history.push('/auth');
          });
      })
      .catch((e) => {
        this.props.history.push('/auth');
      });
  }
  validateInfo(currentValue) {
    //if stepcount is not defined in currentvalue, get it from state
    if (currentValue.stepCount === undefined) {
      currentValue.stepCount = this.state.activeStep;
    }
    // if no current value, return true (button disabled)
    if (!currentValue) {
      return true;
    }
    //check on what step we are
    switch (currentValue.stepCount) {
      case 0:
        //Check if any variable is manually send (due to state updates)
        if (currentValue.first === undefined) {
          currentValue.first = this.state.first;
        }
        //test
        // currentValue.first =
        //   currentValue.first !== undefined ? "og" : "state";
        //always gets "og" ?
        //
        if (currentValue.last === undefined) {
          currentValue.last = this.state.last;
        }
        if (currentValue.birthday === undefined) {
          currentValue.birthday = this.state.birthday;
        }
        if (currentValue.gender === undefined) {
          currentValue.gender = this.state.gender;
        }
        if (currentValue.country === undefined) {
          currentValue.country = this.state.country;
        }
        if (currentValue.unit === undefined) {
          currentValue.unit = this.state.unit;
        }
        //check if any is empty
        if (
          currentValue.first !== '' &&
          currentValue.last !== '' &&
          currentValue.birthday !== '' &&
          currentValue.gender !== '' &&
          currentValue.country !== '' &&
          currentValue.unit !== ''
        ) {
          //button enabled
          return false;
        } else {
          //button disabled. empty input
          return true;
        }

      default:
        return true;
    }
  }

  renderSection() {
    switch (this.state.activeStep) {
      case 0:
        return (
          <div className="fields">
            {/* <h3 className="stepperText">Thank you for registering!</h3> */}
            <Typography className="stepperText" variant="h5">
              Thank you for registering!
            </Typography>
            <p className="stepperText">
              We just need a few more details to finish your registration
            </p>
            <TextField
              name="first"
              ref={this.firstRef}
              className="field"
              label="First name"
              variant="outlined"
              onChange={this.handleChange}
              value={this.state.first}
              required
            />
            <TextField
              name="last"
              ref={this.lastRef}
              className="field"
              label="Last name"
              variant="outlined"
              onChange={this.handleChange}
              value={this.state.last}
              required
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                animateYearScrolling
                className="large-field"
                disableFuture={true}
                format=" DD / MM / yyyy "
                InputAdornmentProps={{ position: 'start' }}
                inputVariant="outlined"
                label="Birthday"
                minDate={new Date().setFullYear(new Date().getFullYear() - 80)}
                maxDate={new Date().setFullYear(new Date().getFullYear() - 13)}
                name="birthday"
                openTo="year"
                onChange={this.handleDateChange}
                ref={this.birthdayRef}
                value={new Date(this.state.birthday)}
              />
            </MuiPickersUtilsProvider>
            <FormControl
              key="gender"
              variant="outlined"
              className="field"
              required
            >
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                name="gender"
                type="select"
                ref={this.genderRef}
                labelId="gender-label"
                variant="outlined"
                label="Gender"
                onChange={this.handleChange}
                value={this.state.gender}
                required
              >
                <MenuItem key="male" value="male">
                  Male
                </MenuItem>
                <MenuItem key="female" value="female">
                  Female
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              key="unit"
              variant="outlined"
              className="field"
              required
            >
              <InputLabel id="unit-label">Unit</InputLabel>
              <Select
                name="unit"
                type="select"
                ref={this.unitRef}
                labelId="unit-label"
                variant="outlined"
                label="Unit"
                onChange={this.handleChange}
                value={this.state.unit}
                required
              >
                <MenuItem key="metric" value="metric">
                  Metric
                </MenuItem>
                <MenuItem key="imperial" value="imperial">
                  Imperial
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              key="country"
              variant="outlined"
              className="large-field"
              required
            >
              <Autocomplete
                id="combo-box-demo"
                freeSolo
                options={this.countriesList}
                variant="outlined"
                value={this.state.country}
                onChange={this.onTagsChange}
                getOptionLabel={(option) =>
                  option.name ? option.name : option
                }
                renderInput={(c) => (
                  <TextField
                    {...c}
                    name="country"
                    label="Country"
                    variant="outlined"
                    value={c.name}
                  />
                )}
              />
            </FormControl>
          </div>
        );
      case 1:
        return (
          <div className="fields">
            <Typography className="stepperText" variant="h6">
              Optional: <br />
              Link your Social media to your Oga Fitness account
            </Typography>
            {/* <p className="stepperText">Link your Social media to your Oga Fitness account</p> */}
            <Fab
              className="connectButtons"
              id="discordConnectButton"
              variant="extended"
              disabled
            >
              <img src="/assets/images/disc.svg" alt="" />
              <span className="connectButtonsText">Connect to Discord</span>
            </Fab>
            <Fab
              className="connectButtons"
              id="instagramConnectButton"
              variant="extended"
              disabled
            >
              <img src="/assets/images/inst.svg" alt="" />
              <span className="connectButtonsText">Connect to Instagram</span>
            </Fab>
          </div>
        );
      case 2:
        return (
          <div className="fields">
            <Typography className="stepperText" variant="h4">
              Finished!
            </Typography>
            <Typography className="stepperText" variant="h5">
              We've sent you an email with a link to finish your registration!
            </Typography>
          </div>
        );
      default:
        break;
    }
  }

  render() {
    return (
        <div className="stepperPage">

      <div className="stepperSection">
        <Stepper
          className="stepper"
          activeStep={this.state.activeStep}
          style={{ backgroundColor: 'transparent' }}
        >
          <Step key={0}>
            <StepLabel></StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>

        {/* Visible stepper step */}
        <div>{this.renderSection()}</div>

        {/* Controls */}
        <div className="controls">
          <div className="left">
            {this.state.activeStep === 0 ? null : (
              <IconButton
                name="nextButton"
                className="round-btn"
                onClick={this.handleBack}
              >
                <ArrowBack />
              </IconButton>
            )}
          </div>

          <div className="right">
            {this.state.activeStep === 2 ? (
              <Link to="/dashboard">
                <IconButton
                  name="nextButton"
                  className="round-btn"
                  onClick={this.handleNext}
                  disabled={this.state.disabledButton}
                >
                  {/* finish  */}
                  <Check />
                </IconButton>
              </Link>
            ) : (
              <IconButton
                name="nextButton"
                className="round-btn"
                onClick={this.handleNext}
                disabled={this.state.disabledButton}
              >
                <ArrowForward />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      </div>
    );
  }
}

// eslint-disable-next-line
// String.prototype.capitalize = function () {
//   return this.charAt(0).toUpperCase() + this.slice(1);
// };

export default RegisterStepper;
