import React from 'react';
import { Button, Card, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import './dashboard.scss';
import { Link } from 'react-router-dom';
//icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import AdbIcon from '@material-ui/icons/Adb';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import API from '../../../components/API/API';

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.openLogoutDialog = this.openLogoutDialog.bind(this);
    this.closeLogoutDialog = this.closeLogoutDialog.bind(this);
    this.logout = this.logout.bind(this);

    this.state = {
      name: '',
      permissions: [],
      logoutDialog: false
    };
  }
  content = [
    {
      id: 'userSettings',
      name: 'User Settings',
      icon: AccountCircleIcon,
      link: '/account/user',
      type: 'USER',
    },
    {
      id: 'accountSettings',
      name: 'Account Settings',
      icon: SettingsIcon,
      link: '/account/settings',
      type: 'USER',
    },
    {
      id: 'paymentSettings',

      name: 'Payment Settings',
      icon: CreditCardIcon,
      link: '/account/payment',
      type: 'USER',
      visibility: false,
    },
    {
      id: 'ADMINBetaKeys',
      name: 'ADMIN: Add Beta user',
      icon: AdbIcon,
      link: '/admin/betausers',
      type: 'ADMIN',
      //visibility: false, //OPTIONAL! This overides type. Both showing and hiding
    },
    // {
    //   id: "Connections",

    //   name: "connections",
    //   icon: AllInclusiveIcon,
    //   link: "/account/connections",
    //   childComponent: UserSettings,
    // },
    // {
    //   id: "ogaFitApps",

    //   name: "Oga Fit Apps",
    //   icon: AllInclusiveIcon,
    //   link: "/account/connections",
    //   childComponent: UserSettings,
    // },
  ];
  showCard(type, hide = undefined) {
    if (hide !== undefined) {
      return hide;
    }
    let retVal = false;
    switch (type) {
      case 'USER':
        retVal = true;
        break;
      case 'ADMIN':
        this.state.permissions.forEach((element) => {
          if (element === 'ADMIN') {
            retVal = true;
          }
        });
        break;
      default:
        break;
    }
    return retVal;
  }

  componentDidMount() {
    function isNotEmpty(v){
      return !(v === undefined || v === null  || v === '')
    }
    API.isLoggedIn()
      .then((e) => {
        if (e.data.auth !== true) {
          this.props.history.push('/auth');
        }
        API.getUserInfo(['first', "last", "birthday", "gender", "country"])
          .then((e) => {
            if (isNotEmpty(e.data.getUserInfo.first) && isNotEmpty(e.data.getUserInfo.last) && isNotEmpty(e.data.getUserInfo.birthday) && isNotEmpty(e.data.getUserInfo.gender) && isNotEmpty(e.data.getUserInfo.country)) {
              API.getUser(['permissions'])
                .then((res) => {
                  this.setState({
                    ...this.state,
                    //dont put ...e.data.getUserInfo because we need capitalization
                    first: e.data.getUserInfo.first.capitalize(),
                    permissions: res.data.getUser.permissions,
                  });
                })
                .catch((e) => {});
            } else {
              this.props.history.push('/auth/registration');
            }
          })
          .catch((e) => {
            this.props.history.push('/auth/registration');
          });
      })
      .catch((e) => {
        this.props.history.push('/auth');
      });
  }
  logout() {
    API.clear();
    localStorage.removeItem('token');
    this.props.history.push('/auth');
  }
  openLogoutDialog(){
    this.setState({
      ...this.state,
      logoutDialog: true
      
    });
  };

  closeLogoutDialog(){
    this.setState({
      ...this.state,
      logoutDialog: false
    });
  };

  render() {
    return (
      <>
        <div id="dashboardMain">
          <div id="banner">
            <Link className="homeLink" to="/">
              <h1 id="authTitle">OGA Fitness</h1>
            </Link>
            <Button id="userTitle" onClick={this.openLogoutDialog}>
              Logout
            </Button>
            <Dialog
              open={this.state.logoutDialog}
              onClose={this.closeLogoutDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to log out?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={this.closeLogoutDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.logout} color="primary" autoFocus>
                  Logout
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <h2>
            {this.state.first
              ? `Welcome to your dashboard, ${this.state.first}`
              : 'Welcome to your dashboard'}
          </h2>
          <div id="dashboardItems">
            {this.content.map((c) => (
              <Button
                style={
                  this.showCard(c.type, c.visibility) ? {} : { display: 'none' }
                }
                key={c.id}
                onClick={() => {
                  this.props.history.push(c.link);
                }}
                className="dashboardItemButton"
              >
                <div></div>
                <Card
                  className={c.type === 'ADMIN' ? "dashboardItemAdmin dashboardItemContent" : "dashboardItemContent"}
                >
                  <span className="dashboardItemTitle">{c.name}</span>
                  <c.icon className="dashboardItemIcon" />
                </Card>
              </Button>
            ))}
          </div>
        </div>
      </>
    );
  }
}
