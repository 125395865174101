import React from "react";

// Components
import { Container, Button,Drawer } from "@material-ui/core";
// import Drawer from '@material-ui/core/Drawer';
import ToS from '../../components/Texts/ToS';
import PrivPol from '../../components/Texts/PrivPol';

export default function DrawerTest() { 
  const [state, setState] = React.useState({bottom: false, ver: ""});
  const openDrawer = (ver) => {
    setState({ ...state, state, "bottom": true, "ver": ver});
  };
  const closeDrawer = () => {
    setState({ ...state, state, "bottom": false });
  };
    return (
      <Container className="Page" >
                <Button onClick={() => openDrawer("term")}>Terms of service</Button>
                <Button onClick={() => openDrawer("privpol")}>Privacy Policy</Button>
                <div >
                <Drawer  
                  anchor={"bottom"} 
                  open={state["bottom"]}
                  onClose={closeDrawer}
                  classes={{
                    paper: "drawerKTest",
                  }}
                > 
                  <div className="drawerTest">
                    {state.ver === "privpol" ? <PrivPol closeDrawerFunction={closeDrawer}></PrivPol>:<ToS closeDrawerFunction={closeDrawer}></ToS>}
                </div>
                </Drawer>
                </div>
        
      </Container>
    );
}
