import React from 'react';

// Components
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Container, Button } from '@material-ui/core';
import ResponsiveDialog from './Support/Support';
import DrawerTest from './Drawer';
import Swiper from 'swiper';
import 'swiper/swiper.scss';
// Styles
import './Home.scss';
import Background from './../../components/background/background.jsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import API from '../../components/API/API';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#007ac1',
    },
  },
});

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }
  useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  });

  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
    authButtonText: 'Login',
    authButtonLink: '/auth',
  };

  slideData = [
    {
      name: 'Body Tracker',
      class: 'product bodyTrackerItem',
      description: 'The best app to track your physical variables',
      img: 'assets/images/phone.svg',
      buttonText: 'Coming soon...',
      link: 'http://ogafit.com/#',
      disabled: true,
    },
    {
      name: 'Team Bicep Fitness Community',
      class: 'product teamBicepItem',
      description:
        'Team Bicep is a fitness community for all ages! You can ask questions or tell your story here. We are a server for beginners and veterans.',
      img: 'assets/images/teambicep.svg',
      buttonText: 'Visit Team Bicep',
      link: 'http://teambicep.com/',
      disabled: false,
    },
  ];

  config = {
    pagination: { el: '.swiper-pagination', clickable: true },
    autoHeight: true,
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    slidesPerView: 1.3,
    breakpoints: {
      '@1.01': {
        slidesPerView: 1.7,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: true,
  };

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    this.setState({ ...this.state, [anchor]: open });
  };

  componentDidMount() {
    function isNotEmpty(v) {
      return !(v === undefined || v === null || v === '');
    }
    new Swiper('.swiper-container', this.config);
    API.isLoggedIn()
      .then((e) => {
        if (e.data.auth === true) {
          API.getUserInfo(['first', 'last', 'birthday', 'gender', 'country'])
            .then((e) => {
              if (
                isNotEmpty(e.data.getUserInfo.first) &&
                isNotEmpty(e.data.getUserInfo.last) &&
                isNotEmpty(e.data.getUserInfo.birthday) &&
                isNotEmpty(e.data.getUserInfo.gender) &&
                isNotEmpty(e.data.getUserInfo.country)
              ) {
                if (
                  e.data.getUserInfo.first ||
                  e.data.getUserInfo.first !== ''
                ) {
                  this.setState({
                    ...this.state,
                    authButtonLink: '/dashboard',
                    authButtonText: 'Your dashboard',
                  });
                } else {
                  this.setState({
                    ...this.state,
                    authButtonLink: '/auth/registration',
                    authButtonText: 'Finish your registration',
                  });
                }
              } else {
                this.setState({
                  ...this.state,
                  authButtonLink: '/auth/registration',
                  authButtonText: 'Finish your registration',
                });
              }
            })
            .catch((e) => {
              this.setState({
                ...this.state,
                authButtonLink: '/auth/registration',
                authButtonText: 'Finish your registration',
              });
            });
        }
      })
      .catch((e) => {
      });
  }

  render() {
    return (
      <Container className="Page">
        <Background className="particlesTest" />
        <div className="section">
          <div id="title">
            <h1>OGA</h1>
            <h1>Fitness</h1>
            <h2 className="slogan">Fitness Digitalized</h2>
            <ThemeProvider theme={theme}>
              <Link to={this.state.authButtonLink} className="homeLoginLink">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="loginSubmit"
                >
                  {this.state.authButtonText}
                </Button>
              </Link>
            </ThemeProvider>
          </div>
        </div>
        <div className="section" style={{ backgroundColor: 'rgb(18, 18, 18)' }}>
          <div id="about">
            <h2>About Oga Fitness</h2>
            <img src="assets/images/logo.svg" alt="logo" />
            <p>
              Oga Fitness, or Oga Fit for short, is a software company creating
              fitness software. Our goal is to make fitness tools and knowledge
              easily accessible. To connect people. To let them know that even
              you can reach your goals!
            </p>
            <p>
              Oga Fitness is a startup company. Started by a small group of
              students with a dream. To make a fitness app that is easy to use.
              That gives you advice while you reach your goal. And help you to
              stay motivated. And all of that for the lowest price possible.
              During the development of that app, Oga Fit came to exist
            </p>
          </div>
        </div>
        <div
          className="section"
          style={{ backgroundColor: ' rgba(0, 0, 0, 0.486)' }}
        >
          <div id="products">
            <h2>Our products</h2>
            <p>These are the services brought by Oga Fit</p>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {this.slideData.map((el) => (
                  <div className="swiper-slide" key={el.name}>
                    <div className={el.class}>
                      <h2 className="title">{el.name}</h2>
                      <img src={el.img} alt="logo" className="previewLogo" />
                      <p>{el.description}</p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={el.link}
                        disabled={el.disabled}
                      >
                        <button
                          href={el.link}
                          mat-raised-button="true"
                          disabled={el.disabled}
                        >
                          {el.buttonText}
                        </button>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="section" style={{ backgroundColor: 'rgb(18, 18, 18)' }}>
          <div id="services">
            <h2>Other services</h2>
            <p>Other services or support provided by Oga Fit</p>
            <div id="mainBlock">
              {/* <Link  className="link" to="/termsofservice">
                <Button>
                Terms of service
                </Button>
                </Link>
                <Link  className="link" to="/privacypolicy">
                <Button>
                Privacy Policy
                </Button>
              </Link> */}

              <DrawerTest className="drawerKTest"></DrawerTest>
              <ResponsiveDialog></ResponsiveDialog>
            </div>
            <div id="footer">
              <span>
                Oga Fitness © Team Bicep 2019-2020 All Rights Reserved.
                KVK:77259718
              </span>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
