// import React, { useEffect } from "react";
import { gql, InMemoryCache,createHttpLink, ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const headerLink = setContext((request, previousContext) => ({
  headers: {
    // Make sure you include any existing headers!
    ...previousContext.headers,
    authorization: localStorage.getItem('token')
  },
  
}));
const link = createHttpLink({
  uri: 'https://api.ogafit.dev/graphql',
  // credentials: 'same-origin'
});
class API {
  client = new ApolloClient({
    // uri: "http://localhost:4000/graphql",
    cache: new InMemoryCache(),
    // cache: false,
    // headers: {
    //   Authorization: localStorage.getItem("token") || null,
    // },
    link: headerLink.concat(link),

  });
  clear(){
    this.client.resetStore()
  }
  getUser(arg) {
    let apiArgString = ""
    if(!arg)
    return

    //maybe not needed. gotta test
    arg.forEach(element => {
      apiArgString += element + ","  
    });
    const queryString = gql`
    query Query {
      getUser{
        ${apiArgString}
      }
    }
  `;
  return this.client.mutate({
    mutation: queryString,
  });
  }
  getUserInfo(arg) {
    let apiArgString = ""
    if(!arg)
    return

    //maybe not needed. gotta test
    arg.forEach(element => {
      apiArgString += element + ","  
    });
    const queryString = gql`
    query Query {
      getUserInfo{
        ${apiArgString}
      }
    }
  `;
  return this.client.mutate({
    mutation: queryString,
  });
  }
  getUserSettings(arg) {
    let apiArgString = ""
    if(!arg)
    return

    //maybe not needed. gotta test
    arg.forEach(element => {
      apiArgString += element + ","  
    });
    const queryString = gql`
    query Query {
      getUserSettings{
        ${apiArgString}
      }
    }
  `;
  return this.client.mutate({
    mutation: queryString,
  });
  }
  /* 
 hello() {
    const IS_COMPANY_NAME_EXIST = gql`
      query {
        hello
      }
    `;

    return this.client
      .query({
        query: IS_COMPANY_NAME_EXIST,
        fetchPolicy: "network-only",
      })
  }
*/

  //auth
  login(email, password) {
    const mutationString = gql`
          mutation Mutation {
              login(email: "${email}", password: "${password}")
            }
        `;
    return this.client.mutate({
      mutation: mutationString,
      variables: { email, password },
    });
  }
  register(email, password) {
    const mutationString = gql`
          mutation Mutation {
              register(email: "${email}", password: "${password}")
            }
        `;
    return this.client.mutate({
      mutation: mutationString,
      variables: { email, password },
    });
  }
  isLoggedIn() {
    const mutationString = gql`
      mutation Mutation {
        auth
      }
    `;
    return this.client.mutate({
      mutation: mutationString,
      fetchPolicy: "no-cache",
    });
  }
  verifyRegistration(token) {
    const mutationString = gql`
    mutation verifyRegistration{
      verifyRegistration(token:"${token}")
    }
    `;
    return this.client.mutate({
      mutation: mutationString,
      fetchPolicy: "no-cache",
      variables: { token },
    });
  }

  //user
  updateUser(arg) {
    let apiArgString = ""
    if(!arg)
    return

    //maybe not needed. gotta test
    Object.keys(arg).forEach(function(key){
      apiArgString  += key + ':"' + arg[key]+'",'
  });
    const mutationString = gql`
          mutation Mutation {
            updateUser(${apiArgString})
            }
        `;
    return this.client.mutate({
      mutation: mutationString,
      variables: { arg },
    });
  }
  updateSettings(arg) {
    let apiArgString = ""
    if(!arg)
    return

    //maybe not needed. gotta test
    Object.keys(arg).forEach(function(key){
      apiArgString  += key + ':"' + arg[key]+'",'
  });
    const mutationString = gql`
          mutation Mutation {
            updateSettings(${apiArgString})
            }
        `;
    return this.client.mutate({
      mutation: mutationString,
      variables: { arg },
    });
  }
  //beta user
  addBetaUser(arg) {
    let apiArgString = ""
    if(!arg)
    return

    //maybe not needed. gotta test
    Object.keys(arg).forEach(function(key){
      apiArgString  += key + ':"' + arg[key]+'",'
  });
    const mutationString = gql`
          mutation Mutation {
            addBetaUser(${apiArgString})
            }
        `;
    return this.client.mutate({
      mutation: mutationString,
      variables: { arg },
    });
  }
  getBetaUsers(arg) {
    let apiArgString = ""
    if(!arg)
    return

    //maybe not needed. gotta test
    arg.forEach(element => {
      apiArgString += element + ","  
    });
    const queryString = gql`
    query Query {
      getBetaUsers{
        ${apiArgString}
      }
    }
  `;
  return this.client.mutate({
    mutation: queryString,
  });
  }

}
export default new API();
