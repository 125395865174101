import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
// import './assets/Azonix.oft'
// import { ApolloClient, InMemoryCache } from "@apollo/client";
// import { ApolloProvider } from "@apollo/client/react";

// const client = new ApolloClient({
//   uri: "http://localhost:4000/graphql",
//   cache: new InMemoryCache(),
//   fetchOptions: {
//     Authorization:
//       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJtaXNzaW9ucyI6WyJBRE1JTiIsIlVTRVIiXSwiX2lkIjoiNjA3ZWQ3YzEwNWU3NDgxNjE0MDM5MjJlIiwiZW1haWwiOiJkZXZlbG9wZXJAb2dhZml0LmxvY2FsIiwiaWF0IjoxNjE4OTI1NzA1fQ.7p_g14hkZdeWeyRuyU2coS_iqee5FPAoqK2P5JFM8UI",
//   },
// });

ReactDOM.render(
  <React.StrictMode>
    {/* <ApolloProvider client={client}>. */}
      <Provider store={store}>
        <App />
      </Provider>
    {/* </ApolloProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
