import React, { Component } from 'react';
import './betaInterface.scss';
import {
  ListItemText,
  Avatar,
  ListItem,
  ListItemAvatar,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';
import API from '../../../../components/API/API';
import { Fragment } from 'react';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      newUserEmail: '',
      list: [],
    };
    this.dialog = false;
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitNewUser = this.submitNewUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleClickOpen() {
    this.setState({ ...this.state, dialog: true });
  }
  handleClose() {
    this.setState({ ...this.state, newUserEmail: '', dialog: false });
  }
  submitNewUser() {
    API.addBetaUser({ email: this.state.newUserEmail })
      .then((e) => {
        API.getBetaUsers(['email', 'addedBy', 'registered', 'updatedAt'])
          .then((res) => {
            if (res.data.getBetaUsers && res.data.getBetaUsers !== null) {
              this.setState({
                ...this.state,
                newUserEmail: '',
                dialog: false,
                list: res.data.getBetaUsers,
              });
            }
          })
          .catch((e) => {
            this.props.history.push('/auth');
          });
      })
      .catch((e) => {
      });
  }
  handleChange(e) {
    this.setState({
      ...this.state,
      newUserEmail: e.target.value,
    });
  }
  tempListArray = [
    {
      email: 'betauser@unused.com',
      date: new Date('02-january-1998'),
      icon: ClearIcon,
    },
    {
      email: 'betauser@used.com',
      date: new Date('02-january-1998'),
      icon: CheckIcon,
    },
  ];

  componentDidMount() {
    API.isLoggedIn()
      .then((e) => {
        if (e.data.auth !== true) {
          this.props.history.push('/auth');
        }
        API.getBetaUsers(['email', 'addedBy', 'registered', 'updatedAt'])
          .then((res) => {
            if (res.data.getBetaUsers && res.data.getBetaUsers !== null) {
              this.setState({
                ...this.state,
                list: res.data.getBetaUsers,
              });
            }
          })
          .catch((e) => {
            this.props.history.push('/auth');
          });
      })
      .catch((e) => {
        this.props.history.push('/auth');
      });
  }

  render() {
    return (
      <>
        <div id="betaInterface">
          <div id="betaUsersTitle">
            <h3>Beta Users</h3>
          </div>
          <Divider />
          <div id="betaUserList">
            {this.state.list.map((c) => (
              <Fragment key={c.email}>
                <ListItem >
                  <ListItemAvatar>
                    <Avatar
                      style={
                        c.registered
                          ? { backgroundColor: '#aed581' }
                          : { backgroundColor: '#e57373' }
                      }
                      >
                      {/* <c.icon /> */}
                      {c.registered ? (
                        <CheckIcon />
                      ) : (
                        <ClearIcon />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={c.email}
                    secondary={
                      'Updated at: ' +
                      new Date(c.updatedAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                    }
                    />
                  <ListItemText secondary={'Added by ' + c.addedBy} />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </div>
          <div id="addBetaUser">
            <Divider />
            <Link className="linkFix" to="/dashboard">
              <Button className="betaUserButton" variant="contained">
                Dashboard
              </Button>
            </Link>
            <Button
              onClick={this.handleClickOpen}
              className="betaUserButton"
              variant="contained"
            >
              Add beta user
            </Button>
          </div>
        </div>
        <Dialog
          open={this.state.dialog}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add beta user</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter a user's email to let them get access to beta account
            </DialogContentText>
            <TextField
              autoFocus
              onChange={this.handleChange}
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              value={this.state.newUserEmail}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.submitNewUser} color="primary">
              Add user
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
